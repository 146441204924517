/* eslint-disable no-unused-vars */
import axios from "axios";
import {
  Toast
} from "vant";

let BASE_URL = process.env.VUE_APP_BASE_URL;
const service = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
});

// service.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded;charset=UTF-8";

//请求拦截器
service.interceptors.request.use(
  (config) => {
    // let Authorization = sessionStorage.getItem("bipToken")
    // if (Authorization) {
    //   config.headers["Authorization"] = Authorization;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器
service.interceptors.response.use(
  (response) => {
    const responseCode = response.status;
    if (responseCode === 200 || responseCode === 204) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    // 断网 或者 请求超时 状态
    if (!error.response) {
      // 请求超时状态
      if (error.message.includes("timeout")) {
        console.log("超时了");
        Toast.fail("请求超时，请检查网络是否连接正常");
      } else {
        // 可以展示断网组件
        console.log("断网了");
        Toast.fail("请求失败，请检查网络是否已连接");
      }
      return;
    }
    const responseCode = error.response.status;
    // console.log(responseCode)
    switch (responseCode) {
      case 401: // 401 未登录
        Toast.fail("接口401");
        break;
      case 404: //请求接口不存在
        Toast.fail("接口404");
        break;
      case 500: //服务器错误
        Toast.fail("服务器错误");
        break;
      default:
        Toast.fail(error.response.data);
    }
    return Promise.reject(error.response.data);
  }
);

/**
 * 封装get请求
 * @param {请求地址} url
 * @param {请求参数} params
 * @returns {Promise}
 */
export function fetch(url, params = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params,
        headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装post请求
 * @param {请求地址} url
 * @param {请求参数} data
 */
export function post(url, data = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    service
      .post(url, data, {
        headers
      })
      .then((res) => {
        if (res == undefined) {
          throw new Error("请求失败，请检查网络是否已连接");
        }
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装put请求
 * @param {请求地址} url
 * @param {请求参数} data
 */
export function put(url, data = {}, headers = {}) {
  return new Promise((resolve, reject) => {
    service
      .put(url, data, {
        headers
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * 封装delete请求
 * @param {请求地址} url
 * @param {请求参数} data
 */
export function remove(url, data = {}) {
  return new Promise((resolve, reject) => {
    service
      .delete(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default service;